
import './App.css';
import Header from './components1/Header';
import Footer from './components1/Footer';
import Title from './components1/Title';



function App() {
  return (
    <div className='App'>
      <Header></Header>
      <Footer></Footer>
      <Title></Title>
    </div>
      
  );
}

export default App;
