
import './Footer.css';
import a2 from './images/stj.jpg';
function Footer(){
    return(
        <div>  
        <div className='ai'>
        <img src={a2} alt="stjit" className='a2'></img>
    </div>
     </div>
      

    );

}
export default Footer;
